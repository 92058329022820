<template>
  <div v-if="data">
    <template
      v-if="
        false &&
          propsValue &&
          propsValue.orderAdjustment &&
          propsValue.orderAdjustment.length > 0
      "
    >
      <div class="d-flex justify-end align-end">
        <v-btn
          depressed
          dark
          color="primary"
          @click="openOrderAdjustmentRecordDialog = true"
        >
          查看訂單調整
        </v-btn>
      </div>
    </template>
    <template v-if="receivingDate && receivingDate.length > 0">
      <block-title
        class="d-flex justify-space-between"
        @click.native="receivingDateInfoCollapse = !receivingDateInfoCollapse"
      >
        <span>商店營業時間</span>
        <v-icon v-if="receivingDateInfoCollapse" color="primary"
          >fa fa-chevron-down</v-icon
        >
        <v-icon v-else color="primary">fa fa-chevron-up</v-icon>
      </block-title>
      <div v-show="!receivingDateInfoCollapse">
        <v-row v-for="(dayV, dayI) in receivingDate" :key="`day${dayI}`">
          <v-col cols="4">{{ dayV.text }}</v-col>
          <v-col cols="8">
            <span
              v-for="(rangeV, rangeI) in dayV.range"
              :key="`range${rangeI}`"
              class="pr-2"
            >
              {{ rangeV.start || "∞" }}~{{ rangeV.end || "∞" }}
            </span>
          </v-col>
        </v-row>
      </div>
    </template>
    <block-title>訂購品項</block-title>
    <provider-order-card
      v-for="(item, index) in orderCardData"
      :key="index"
      v-model="orderCardData[index]"
      :configs="cardConfigs(index)"
      :zebraStriping="Number(index)"
    ></provider-order-card>
    <!--  合計  -->
    <number-bar
      v-if="hasBackOrder"
      title="合計"
      unit="元"
      :value="amount"
      :readonly="true"
      name="amount"
      type="primary"
    ></number-bar>

    <!--  總計  -->
    <div v-else>
      <div class="d-sm-none">
        <number-bar
          title="運費"
          unit="元"
          :control="false"
          @change="onChangeDeliveryFee"
          v-model="delivery_fee"
          :readonly="disabled"
          name="delivery_fee"
          type="secondary"
          style="width: 100%; margin-bottom: 0!important; border: 0!important;border-bottom-left-radius:0;border-bottom-right-radius: 0"
        ></number-bar>
        <number-bar
          title="總計"
          unit="元"
          reload
          @reload="onReloadAmount"
          @change="onChangeAmount"
          v-model="total_amount"
          :readonly="disabled"
          name="total_amount"
          type="secondary"
          style="width: 100%; margin-top: 0!important; border: 0!important;border-top-left-radius:0;border-top-right-radius: 0"
        ></number-bar>
      </div>
      <div class="d-none d-sm-flex">
        <number-bar
          title="運費"
          unit="元"
          :control="false"
          @change="onChangeDeliveryFee"
          v-model="delivery_fee"
          :readonly="disabled"
          name="delivery_fee"
          type="secondary"
          style="width: 100%; border: 0!important;border-top-right-radius:0;border-bottom-right-radius: 0"
        ></number-bar>
        <number-bar
          title="總計"
          unit="元"
          reload
          @reload="onReloadAmount"
          @change="onChangeAmount"
          v-model="total_amount"
          :readonly="disabled"
          name="total_amount"
          type="secondary"
          style="width: 100%; border: 0!important;border-top-left-radius:0;border-bottom-left-radius: 0"
        ></number-bar>
      </div>
    </div>
    <div
      v-if="logistic_orders && logistic_orders.length > 0"
      class="mb-5 px-3 pt-1 pb-4"
      style="border: solid 1px #bababa;"
    >
      <div
        v-for="(item, index) in logistic_orders"
        :key="`${item.provider_no}${index}`"
        class="px-3 py-3 my-2"
        style="border: solid 1px #bababa;position: relative;"
      >
        <div class="pt-3 pb-1 font-weight-bold">物流商</div>
        <v-select
          outlined
          hide-details
          dense
          label="請選擇物流商"
          :items="logisticList"
          :item-text="`text`"
          :item-value="`value`"
          v-model="item.provider_no"
          :readonly="!(isApply || isCreated)"
          :name="`logistic_provider[${index}][provider_no]`"
        ></v-select>

        <div class="pt-3 pb-1 font-weight-bold">物流單號</div>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="item.no"
          :readonly="!(isApply || isCreated)"
          :name="`logistic_provider[${index}][no]`"
        ></v-text-field>
        <v-icon
          v-if="isApply || isCreated"
          style="position: absolute; top: 5px; right: 5px;"
          @click="clickRemoveLogisticOrder(index)"
          >ri-close-line</v-icon
        >
      </div>
      <div
        v-if="isApply || isCreated"
        class="d-flex justify-center align-middle py-1"
        style="border: solid 1px #bababa;"
        @click="clickAddLogisticOrder"
      >
        <v-icon>ri-add-line</v-icon>
      </div>
    </div>
    <div class="mb-5 px-3 pt-1 pb-4" style="border: solid 1px #bababa;">
      <div class="pt-3 pb-1 font-weight-bold">出貨日期</div>
      <datetime-picker
        type="date"
        format="date"
        v-model="delivery_date"
        name="delivery_date"
        placeholder="請選擇日期"
        :readonly="!(isApply || isCreated)"
      ></datetime-picker>

      <div class="pt-3 pb-1 font-weight-bold">預計到貨天數</div>
      <number-input
        v-model="estimated_delivery_days"
        :max="999"
        name="estimated_delivery_days"
        :readonly="!(isApply || isCreated)"
        :control="isApply || isCreated"
      />

      <div class="pt-3 pb-1 font-weight-bold">到貨時間</div>
      <div class="d-flex justify-center align-middle">
        <datetime-picker
          type="time"
          format="HH:mm"
          :range="false"
          :prependIcon="false"
          :timePickerOptions="{
            start: '00:00',
            step: '00:30',
            end: '24:00',
            format: 'HH:mm',
          }"
          v-model="delivery_time_start"
          name="delivery_time_start"
          placeholder="請選擇"
          :readonly="!(isApply || isCreated)"
        ></datetime-picker>
        <span class="px-2 py-3">~</span>
        <datetime-picker
          type="time"
          format="HH:mm"
          :range="false"
          :prependIcon="false"
          :timePickerOptions="{
            start: '00:00',
            step: '00:30',
            end: '24:00',
            format: 'HH:mm',
          }"
          v-model="delivery_time_end"
          name="delivery_time_end"
          placeholder="請選擇"
          :readonly="!(isApply || isCreated)"
        ></datetime-picker>
      </div>
    </div>

    <v-textarea
      v-model="comment"
      v-show="commentVisible"
      label="備註"
      outlined
      name="comment"
      auto-grow
      disabled
    ></v-textarea>
    <v-btn
      depressed
      dark
      color="primary"
      @click="showProviderComment"
      v-show="!providerCommentVisible && !isAfterSended"
    >
      填寫備註
    </v-btn>
    <v-textarea
      v-model="provider_comment"
      label="供應商備註"
      outlined
      name="provider_comment"
      auto-grow
      v-show="providerCommentVisible"
      :readonly="isAfterSended"
    ></v-textarea>

    <input
      v-for="(item, index) in data"
      :key="item.item_id"
      hidden
      :name="`items[${index}][item_id]`"
      :value="item.item_id"
    />
    <order-adjustment-record
      :openOrderAdjustmentRecordDialog="openOrderAdjustmentRecordDialog"
      :orderData="propsValue || {}"
      :adjustmentData="(propsValue && propsValue.orderAdjustment) || []"
      :providerId="providerId"
      :storeId="storeId"
      @updateOrderAdjustmentRecordDialogSwitch="
        (isOpen) => (openOrderAdjustmentRecordDialog = isOpen)
      "
    />
  </div>
</template>

<script lang="babel" type="text/babel">
import countMixins from "@/mixins/count.js";
import orderItemMixins from "@/mixins/orderItem.js";
import OrderAdjustmentRecord from "@/modules/store/views/orderDetail/OrderAdjustmentRecord";
import Moment from "moment";

export default {
  mixins: [countMixins, orderItemMixins],
  components: {
    "provider-order-card": () =>
      import("@/modules/base/components/priceCard/order/providerOrderCard.vue"),
    "block-title": () => import("@/modules/base/components/blockTitle.vue"),
    "number-bar": () => import("@/modules/base/components/bar/numberBar.vue"),
    "number-input": () => import("@/modules/base/components/numberInput.vue"),
    OrderAdjustmentRecord,
  },
  data: () => ({
    data: null,
    orderCardData: [],
    comment: null,
    provider_comment: null,
    providerCommentVisible: false,
    percent: 5,
    total_amount: 0,
    commentVisible: false,
    receivingDate: [],
    receivingDateInfoCollapse: true,
    openOrderAdjustmentRecordDialog: false,
  }),
  watch: {
    propsValue: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.propsValue) return;
        this.data = this.$eagleLodash.cloneDeep(this.propsValue.items);
        this.comment = this.propsValue.comment;
        if (this.comment && this.comment.length > 0) {
          this.commentVisible = true;
        }
        this.provider_comment = this.propsValue.provider_comment;
        this.propsAmount = this.propsValue.total_amounts;
        if (
          this.propsValue.provider_comment &&
          this.propsValue.provider_comment.length > 0
        ) {
          this.providerCommentVisible = true;
        } else {
          this.providerCommentVisible = false;
        }
        this.delivery_fee = Number(this.propsValue.delivery_fee).toFixed(0);
        this.total_amount = Number(this.propsAmount);
        this.delivery_date = this.propsValue.delivery_date
          ? this.formatDate(this.propsValue.delivery_date)
          : "";
        this.estimated_delivery_days = this.isApply
          ? this.propsValue.estimated_delivery_days || 1
          : this.propsValue.estimated_delivery_days;
        this.delivery_time_start = this.isApply
          ? this.propsValue.delivery_time_start || ""
          : this.propsValue.delivery_time_start;
        this.delivery_time_end = this.isApply
          ? this.propsValue.delivery_time_end || ""
          : this.propsValue.delivery_time_end;
        const logisticData = [
          ...this.propsValue.logisticList.preference,
          ...this.propsValue.logisticList.arr,
        ];
        this.logisticList = logisticData.map((v) => ({
          text: this.propsValue.logisticList.map[v].name,
          value: v,
        }));
        this.logistic_orders = this.isApply
          ? this.propsValue.logistic_orders &&
            this.propsValue.logistic_orders.length
            ? this.propsValue.logistic_orders
            : [{ no: "", provider_no: "" }]
          : this.propsValue.logistic_orders &&
            this.propsValue.logistic_orders.filter(
              (v) => v.no || v.provider_no
            );
        const dayEnum = [
          { value: 1, text: "星期一", range: [] },
          { value: 2, text: "星期二", range: [] },
          { value: 3, text: "星期三", range: [] },
          { value: 4, text: "星期四", range: [] },
          { value: 5, text: "星期五", range: [] },
          { value: 6, text: "星期六", range: [] },
          { value: 7, text: "星期日", range: [] },
        ];
        this.receivingDate = dayEnum.map((day) => {
          if (!this.propsValue.receivingDate) return day;
          for (const nowDay of this.propsValue.receivingDate) {
            if (
              day.value === nowDay.week &&
              nowDay.times &&
              nowDay.times.length
            ) {
              day.range = nowDay.times.filter((time) => time.start || time.end);
            }
          }
          return day;
        });

        console.log("this.receivingDate:", this.receivingDate);
      },
    },
    data: {
      deep: true,
      async handler() {
        this.orderCardData = this.data.map((item, index) => {
          const price = this.getDiscountPrice({
            discount_config: item.discount_config,
            price: Number(item.price).toFixed(0),
            quantity: Number(item.count).toFixed(0),
          });

          let out_of_stock = false;
          // 出貨數量小於訂購數字 -> 缺貨
          if (item.shipping < item.count) {
            out_of_stock = true;
          }

          return {
            shipping_unit: item.shipping_unit, // 出貨單位
            calculate_unit: item.calculate_unit, // 計價單位
            per_shipping_count: item.per_shipping_count, //  ex: 規格:每籃5包
            shipping_count: Number(this.$helper.reverseShippingCount(
              item.shipping,
              item.shipping_unit
            )).toFixed(0), // 出貨數量
            price: Number(item.price).toFixed(0), // 單價
            order_count: this.$helper.reverseShippingCount(
              item.count,
              item.shipping_unit
            ), // 訂購數量 / 數量
            out_of_stock: out_of_stock,
          };
        });
        // console.log(this.orderCardData )
        // 一開始要等子元件渲染完
        await this.$helper.delay(0.5);
        await this.getChildrenTotal();
      },
    },
    orderCardData: {
      deep: true,
      async handler() {
        await this.getChildrenTotal();
      },
    },
    amount: {
      deep: true,
      async handler() {
        await this.reCalcTotalAmount();
      },
    },
  },
  computed: {
    // 訂單狀態
    status() {
      return this.propsValue.status;
    },
    // isRequestReturn() {
    //   return ['request_return', 'canceled'].includes(this.status)
    // },
    hasBackOrder() {
      return !!this.propsValue.backOrder;
    },
    isCreated() {
      return ["created"].includes(this.status);
    },
    isApply() {
      return ["apply"].includes(this.status);
    },
    isAfterSended() {
      return ["sended", "finished", "canceled", "reject"].includes(this.status);
    },
    disabled() {
      return !["created", "apply"].includes(this.status);
    },
    usePropsAmount() {
      return !["created", "apply"].includes(this.status);
    },
    calc_total_amount() {
      return Number(this.amount) + Number(this.delivery_fee);
    },
    providerId() {
      return this.$store.getters[`member/providerId`];
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
  },
  methods: {
    showProviderComment() {
      this.providerCommentVisible = true;
    },
    cardConfigs(index) {
      const item = this.data[index];

      if (this.status == "canceled") {
        return [
          { id: "product_name", label: item.product, colon: false },
          { id: "order_count", label: "訂購數量", col: 6 },
          { id: "unit", col: 6 },
        ];
      }

      return [
        { id: "product_name", label: item.product, colon: false },
        { id: "order_count", label: "訂購數量", col: 6 },
        { id: "unit", col: 6 },
        {
          id: "shipping_count",
          label: "出貨數量",
          type: "number-input",
          fixed: 2,
          name: `items[${index}][shipping]`,
          labelColor: "primary--text",
          disabled: this.disabled,
          onChange: (value) => this.onChangePrice(index),
          rule: (count) => this.countRule({ count, unit: item.shipping_unit }),
        },
        {
          id: "price",
          type: "number-input",
          fixed: 2,
          name: `items[${index}][price]`,
          labelColor: "primary--text",
          valueSuffix: "元",
          disabled: this.disabled,
          onChange: (value) => {
            const discountPrice = this.getDiscountPriceByIndex(index);
            if (+value > discountPrice) {
              this.$snotify.error("優惠價格不能高於單價");
              this.$set(this.orderCardData, index, {
                ...this.orderCardData[index],
                price: discountPrice,
              });
            }
          },
        },
        {
          id: "total",
          label: "小計",
          type: "total",
          barType: "primary",
          fixed: 2,
          valueSuffix: "元",
        },
      ];
    },
    getDiscountPriceByIndex(index) {
      const dataItem = this.data[index];
      const orderCardItem = this.orderCardData[index];

      return this.getDiscountPrice({
        discount_config: dataItem.discount_config,
        price: +orderCardItem.price,
        quantity: +orderCardItem.shipping_count,
      });
    },
    onChangePrice(index) {
      const discountPrice = this.getDiscountPriceByIndex(index);
      const item = this.orderCardData[index];
      const discount_config = this.data[index].discount_config;
      console.log("item:", item);
      console.log("discountPrice:", discountPrice);

      if (this.isInDiscountRange(item.shipping_count, discount_config)) {
        const newItem = this.$eagleLodash.cloneDeep(item);
        newItem.price = discountPrice;
        this.$set(this.orderCardData, index, newItem);
        return;
      } else {
        // 不在優惠區間, 變回原價
        const newItem = this.$eagleLodash.cloneDeep(item);
        newItem.price = this.data[index].price;
        this.$set(this.orderCardData, index, newItem);
        return;
      }
    },
    async onChangeAmount(value) {
      await this.$nextTick();
      const newAmount = Number(value);
      const oldAmount = this.$children.reduce((arr, cur) => {
        if (cur.total) arr += Number(cur.total);
        return Math.round(arr);
      }, 0);
      // console.log('newAmount', newAmount)
      // console.log('oldAmount', oldAmount)
      if (oldAmount == newAmount) return;

      const difference = newAmount - oldAmount;

      // console.log('difference', difference)
      // console.log('5%', oldAmount * this.percent)
      if (Math.abs(difference) > oldAmount * (this.percent / 100)) {
        if (difference > 0) {
          this.$snotify.error(
            `總計已高於原本總計的${this.percent}%，請確認調整幅度是否過大`,
            "總計異常提醒"
          );
        }
        if (difference < 0) {
          this.$snotify.error(
            `總計已低於原本總計的${this.percent}%，請確認調整幅度是否過大`,
            "總計異常提醒"
          );
        }
      }
    },
    onReloadAmount() {
      this.handleResetTotal();
      this.reCalcTotalAmount();
    },
    async onChangeDeliveryFee(value) {
      this.reCalcTotalAmount();
    },
    reCalcTotalAmount() {
      if (["created", "apply"].includes(this.status)) {
        this.total_amount = this.$eagleLodash.cloneDeep(this.calc_total_amount);
      } else {
        this.total_amount = Number(this.amount);
      }
    },
    clickAddLogisticOrder() {
      this.logistic_orders.push({
        no: "",
        provider_no: "",
      });
      this.$set(this, "logistic_orders", this.logistic_orders);
      this.$forceUpdate();
    },
    clickRemoveLogisticOrder(index) {
      this.logistic_orders.splice(index, 1);
      this.$set(this, "logistic_orders", this.logistic_orders);
      this.$forceUpdate();
    },
    formatDate(dateString) {
      let date = new Date(dateString);
      let year = date.getFullYear();
      let month = date.getMonth() + 1; // getMonth() 從 0 開始，所以我們需要加 1
      let day = date.getDate();

      let formattedDate = `${year}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
      return formattedDate;
    },
  },
};
</script>
